<template>
  <!-- 智能诊断 -->
  <div class="box">
    <van-nav-bar title="智能诊断" left-arrow @click-left="onClickLeft" />
    <div class="content_box">
      <div class="head">
        <div class="imgBox">
          <img src="../../assets/image/cardManagement/img_Intelligentdiagnosis_d.png" alt="" />
        </div>
        <div class="progress">
          <span class="percent">{{ percent }}</span>
          <p class="word">
            <template v-if="percent < 100">正在检测{{ judgeTitle()
            }}<img alt="logo" src="../../assets/image/intelligentDiagnosis/dot.gif" /></template>
            <template v-else>检测完成</template>
          </p>
        </div>
      </div>
      <div class="account_desc" :class="{ success: dateMsg != '' && flowMsg != '' && statusMsg != '' }">
        <div class="account_content">
          <div class="desc_diagnosis_date">
            <div>
              <img src="../../assets/image/cardManagement/icon_Intelligentdiagnosis_validperiod.png" alt="" />
            </div>
            <div class="tips">有效期</div>
          </div>
          <div class="desc_result" @click="toComponent" v-if="isEffective">
            <div class="result_choice">充值</div>
          </div>
        </div>
        <div class="account_content" :class="{ success: dateMsg != '' }">
          {{ dateMsg }}
        </div>
        <div class="account_content">
          <div class="desc_diagnosis">
            <div>
              <img src="../../assets/image/cardManagement/icon_Intelligentdiagnosis_flow.png" alt="" />
            </div>
            <div class="tips">流量</div>
          </div>
          <div class="desc_result" @click="toComponent" v-if="isBeyondFlow">
            <div class="result_choice">充值</div>
          </div>
        </div>
        <div class="account_content" :class="{ success: flowMsg != '' }">
          {{ flowMsg }}
        </div>
        <div class="account_content">
          <div class="desc_diagnosis">
            <div>
              <img src="../../assets/image/cardManagement/icon_Intelligentdiagnosis_status.png" alt="" />
            </div>
            <div class="tips">状态</div>
          </div>
        </div>
        <div class="account_content" :class="{ success: statusMsg != '' }">
          {{ statusMsg }}
        </div>
      </div>
      <div class="btn" @click="btnClick" :class="{ diagnosisBtn: dateStart || flowStart || statusStart }">
        {{ fnType }}
      </div>
      <!-- <div
        class="btn"
        @click="handleToDistribution" v-if="xyck_agentInfo.isShow"
      >去赚钱充套餐
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  wxAuthUrl,
} from "_API_/zuJiApi";
import { onMounted, reactive, toRefs, getCurrentInstance, computed } from "vue";
import { useRoute } from "vue-router";
import {
  diagnosisEffective,
  diagnosisCardStatus,
  getDiagnosis,
  GetEffectPackage,
  GetStopRestart
} from "_API_/api.services";

export default {
  setup() {
    const state = reactive({
      cardInfo: { iccid: "", status: "", iccids: [], labels: [] }, //卡号信息
      xyck_agentInfo: {
        isShow: false,
        platformCode: null,
        iccid: null,
        iotPhone: '',
      },//是否展示创客分销入口
      iccid: "", //卡号
      percent: 0, //检测百分值
      dateMsg: "", //有效期信息
      dateCode: "", //有效期状态码
      dateStart: false, //有效期展示
      flowMsg: "", //流量期信息
      flowCode: "", //流量状态码
      flowStart: false, //流量展示
      statusMsg: "", //卡号状态信息
      statusCode: "", //卡号状态码
      statusStart: false, //卡号状态展示
      timeId: null, //定时器Id
      isEffective: false, //是否有效期展示
      isBeyondFlow: false, //是否超量展示,
      usageCode: "", //卡使用状态码
      theme: "" //主题名称
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    state.usageCode = route.query.usageCode || "";
    const methods = {
      // 跳转创客分销
      handleToDistribution() {
        location.href = `${wxAuthUrl}/wxAuth/login?type=11&iotPhone=${state.xyck_agentInfo.iotPhone}&platformCode=${state.xyck_agentInfo.platformCode}&iccid=${state.xyck_agentInfo.iccid}`

      },
      //返回上一个页面
      onClickLeft() {
        window.history.back();
      },
      toComponent() {
        if (state.theme === 'YuYin') {
          router.push({
            name: "yy-platfrom"
          });
          return
        }
        if (state.theme === 'AnFang') {
          if (state.cardInfo.labels.includes(2) && (state.cardInfo.userMoney <= 0 && state.cardInfo.cardMoney <= 0)) {
            router.push({
              name: "personal-packageRecharge",
            });
            return
          } else {
            router.push({
              name: "af-platfrom"
            });
            return
          }

        }
        router.push({
          name: "personal-packageRecharge",
          query: { type: 0 }
        });
      },
      //诊断标题
      judgeTitle() {
        if (state.percent <= 25) {
          return "有效期";
        }
        if (25 < state.percent <= 50) {
          return "流量";
        }
        if (0 < state.percent < 100) {
          return "状态";
        }
      },
      //百分数累计
      percentAdd(num) {
        let n = 1;
        let m = 100;
        if (num - state.percent > 35) {
          n = 3;
        }
        if (num - state.percent > 20) {
          n = 2;
          m = 60;
        }
        state.timeId = setTimeout(() => {
          if (state.percent >= num) {
            state.percent = num;
            return;
          }
          state.percent += n;
          ctx.percentAdd(num);
        }, m);
      },
      // 时间限制
      timeLimit(code) {
        if (code == 206) {
          clearTimeout(state.timeId);
          state.dateStart = false;
          state.flowStart = false;
          state.statusStart = false;
          ctx.percentAdd(100);
          return false;
        }
        return true;
      },
      //错误时的回调
      errorFn() {
        tool.alert(
          {
            title: "提示",
            msg: "检测出错，请稍后重试"
          },
          () => {
            if (state.theme === 'AnFang') {
              router.push({
                name: "af-platfrom",
                query: {
                  isRefreshPopup: 1
                },
              });
              return
            }
            router.push({
              name: "personal"
            });
          }
        );
      },
      // 一键优化
      oneKeyFix() {
        GetStopRestart(state.iccid)
          .then(({ data: res }) => {
            if (res.code == 0) {
              tool.alert(
                {
                  title: "提示",
                  msg: "设置成功"
                },
                () => {
                  if (state.theme === 'AnFang') {
                    router.push({
                      name: "af-platfrom",
                      query: {
                        isRefreshPopup: 1
                      },
                    });
                    return
                  }
                  router.push({
                    name: "personal"
                  });
                }
              );
              return;
            }
            tool.alert(
              {
                title: "提示",
                msg: res.msg
              },
              () => {
                if (state.theme === 'AnFang') {
                  router.push({
                    name: "af-platfrom",
                    query: {
                      isRefreshPopup: 1
                    },
                  });
                  return
                }
                router.push({
                  name: "personal"
                });
              }
            );
          })
          .catch(err => {
            console.log(err);
          });
      },
      // 套餐生效
      packageTakeEffect() {
        GetEffectPackage(state.iccid)
          .then(({ data: res }) => {
            if (res.code == 0) {
              tool.alert(
                {
                  title: "提示",
                  msg: "设置成功"
                },
                () => {
                  if (state.theme === 'AnFang') {
                    router.push({
                      name: "af-platfrom",
                      query: {
                        isRefreshPopup: 1
                      },
                    });
                    return
                  }
                  router.push({
                    name: "personal"
                  });
                }
              );
              return;
            }
            tool.alert(
              {
                title: "提示",
                msg: res.msg
              },
              () => {
                if (state.theme === 'AnFang') {
                  router.push({
                    name: "af-platfrom",
                    query: {
                      isRefreshPopup: 1
                    },
                  });
                  return
                }
                router.push({
                  name: "personal"
                });
              }
            );
          })
          .catch(err => {
            console.log(err);
          });
      },
      //按钮事件
      btnClick() {
        // 取消诊断
        if (fnType.value == "取消诊断") {
          clearTimeout(state.timeId);
          state.dateStart = false;
          state.flowStart = false;
          state.statusStart = false;
          tool.alert(
            {
              title: "提示",
              msg: "检测已停止"
            },
            () => {
              if (state.theme === 'AnFang') {
                router.push({
                  name: "af-platfrom",
                  query: {
                    isRefreshPopup: 1
                  },
                });
                return
              }
              router.push({
                name: "personal"
              });
            }
          );
          return;
        }
        if (fnType.value == "立即充值") {
          if (state.theme === 'YuYin') {
            router.push({
              name: "yy-platfrom"
            });
            return
          }
          if (state.theme === 'AnFang') {
            if (state.cardInfo.labels.includes(2) && (state.cardInfo.userMoney <= 0 && state.cardInfo.cardMoney <= 0)) {
              router.push({
                name: "personal-packageRecharge",
              });
              return
            } else {
              router.push({
                name: "af-platfrom",
                query: {
                  isRefreshPopup: 1
                },
              });
              return
            }

          }
          router.push({
            name: "personal-packageRecharge",
            query: { type: 0 }
          });
          return;
        }
        if (fnType.value == "一键优化") {
          ctx.oneKeyFix();
          return;
        }
        if (fnType.value == "立即生效") {
          ctx.packageTakeEffect();
          return;
        }
        if (fnType.value == "检测异常" || fnType.value == "检测完成") {
          if (state.theme === 'AnFang') {
            router.push({
              name: "af-platfrom",
              query: {
                isRefreshPopup: 1
              },
            });
            return
          }
          router.push({
            name: "personal"
          });
          return;
        }
      },
      //流量诊断
      flowJudge(iccid) {
        ctx.percentAdd(75);
        state.flowStart = true;
        getDiagnosis(iccid)
          .then(({ data: res }) => {
            state.flowMsg = res.msg;
            state.flowCode = Number(res.code);
            ctx.timeLimit(res.code);
            ctx.percentAdd(100);
            if (res.code == 203) {
              if (state.isEffective) {
                state.isBeyondFlow = false;
                return;
              }
              state.isBeyondFlow = true;
            }
            if (res.code == 500) {
              state.flowMsg = res.msg;
              return;
            }
          })
          .catch(err => {
            if (Boolean(err == "Cancel")) {
              return;
            }
            ctx.errorFn();
          });
      },
      //流程状态诊断
      statusJudge(iccid) {
        ctx.percentAdd(50);
        state.statusStart = true;
        diagnosisCardStatus(iccid)
          .then(({ data: res }) => {
            state.statusMsg = res.msg;
            state.statusCode = Number(res.code);
            let flag = ctx.timeLimit(res.code);
            if (flag) {
              ctx.flowJudge(iccid);
              if (res.code == 0 && res.msg.indexOf("卡状态异常") != -1) {
                tool.confirm(
                  {
                    title: "提示",
                    msg: "卡片存在异常，是否检测，检测后半小时重启设备",
                    cancelButtonText: "取消",
                    confirmButtonText: "检测"
                  },
                  () => { },
                  () => { }
                );
              }
            }
            if (res.code == 500) {
              state.statusMsg = res.msg;
              return;
            }
          })
          .catch(err => {
            if (Boolean(err == "Cancel")) {
              return;
            }
            ctx.errorFn();
          });
      },

      //有效期检测
      dateJudge(iccid) {
        ctx.percentAdd(25);
        state.dateStart = true;
        diagnosisEffective(iccid)
          .then(({ data: res }) => {
            let flag = ctx.timeLimit(res.code);
            if (flag) {
              ctx.statusJudge(iccid);
            }
            state.dateMsg = res.msg;
            state.dateCode = Number(res.code);
            if (res.code == 203) {
              state.isEffective = true;
              if (
                Number(state.usageCode) == 1 ||
                Number(state.usageCode) == 2 ||
                Number(state.usageCode) == 3 ||
                Number(state.usageCode) == 6 ||
                Number(state.usageCode) == 11 ||
                Number(state.usageCode) == 13
              ) {
                tool.confirm(
                  {
                    title: "提示",
                    msg: "当前卡片即将到期，为避免停用，请尽快充值",
                    cancelButtonText: "取消",
                    confirmButtonText: "去充值"
                  },
                  () => {
                    if (state.theme === 'YuYin') {
                      router.push({
                        name: "yy-platfrom"
                      });
                      return
                    }
                    if (state.theme === 'AnFang') {
                      if (state.cardInfo.labels.includes(2) && (state.cardInfo.userMoney <= 0 && state.cardInfo.cardMoney <= 0)) {
                        router.push({
                          name: "personal-packageRecharge",
                        });
                        return
                      } else {
                        router.push({
                          name: "af-platfrom",
                          query: {
                            isRefreshPopup: 1
                          },
                        });
                        return
                      }

                    }
                    router.push({
                      name: "personal-packageRecharge",
                      query: { type: 0 }
                    });
                  },
                  () => { }
                );
              }
            }
            if (res.code == 207) {
              tool.alert(
                {
                  title: "提示",
                  msg: res.msg,
                  duration: 1500
                },
                () => {
                  router.push({
                    name: "personal"
                  });
                }
              );
              return;
            }

            if (res.code == 500) {
              state.dateMsg = res.msg;
              return;
            }
          })
          .catch(err => {
            console.log(err);
            if (Boolean(err == "Cancel")) {
              return;
            }
            ctx.errorFn();
          });
      },
      //获取信息
      getInfo() {
        //获取用户信息
        const info = store.state.user.userInfo;
        state.cardInfo = store.state.user.userInfo;
        state.iccid = info.iccid;
        if (!Boolean(info.iccid)) {
          router.push({
            name: "personal"
          });
          return;
        }
        //进行有效期检测
        ctx.dateJudge(info.iccid);
      }
    };
    //计算属性=>fnType
    const fnType = computed(() => {
      let fn = "取消诊断";
      if (state.dateStart || state.flowStart || state.statusStart) {
        fn = "取消诊断";
      }
      if (state.flowCode === 203) {
        fn = "立即充值";
      }
      if (state.flowCode === 204) {
        fn = "一键优化";
      }
      if (state.dateCode === 205) {
        fn = "立即生效";
      }
      if (state.dateCode === 500 || state.flowCode === 500) {
        fn = "检测异常";
      }
      if (
        state.dateCode === 0 &&
        state.flowCode === 0 &&
        state.statusCode === 0 &&
        state.percent == 100
      ) {
        fn = "检测完成";
      }
      return fn;
    });

    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      ctx.getInfo();
      //  if (localStorage.getItem('xyck_agentInfo')) {
      //   state.xyck_agentInfo = JSON.parse(localStorage.getItem('xyck_agentInfo'))
      // }
    });
    return {
      ...toRefs(state),
      ...methods,
      fnType
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";

.box {
  height: 100vh;

  .content_box {
    height: calc(100vh - 100px);
    overflow-y: auto;

    .head {
      width: 750px;
      height: 518px;
      @include background_color("background_color1");
      text-align: center;
      padding-top: 20px;

      .imgBox {
        img {
          width: 219px;
          height: 219px;
        }
      }

      .progress {
        width: 100%;
        position: relative;
        text-align: center;
      }

      .progress .percent {
        color: #fff;
        font-size: 40px;
        position: relative;
        display: inline-block;
        margin-top: 20px;
      }

      .progress .percent:after {
        color: #fff;
        font-size: 34px;
        content: "%";
        display: block;
        position: absolute;
        top: 6px;
        right: -32px;
      }

      .progress .word {
        color: #fff;
        letter-spacing: 4px;
        margin-top: 40px;
        padding-left: 44px;
      }

      .progress .word img {
        width: 30px;
        height: 30px;
      }
    }

    .account_desc {
      width: 690px;
      background: #ffffff;
      border-radius: 20px;
      margin: 0 auto;
      position: relative;
      bottom: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .account_content {
        width: 640px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .desc_diagnosis,
        .desc_diagnosis_date,
        .desc_result {
          display: flex;
        }

        .desc_diagnosis div img {
          width: 48px;
          height: 48px;
        }

        .desc_diagnosis_date div img {
          width: 48px;
          height: 48px;
        }

        .desc_diagnosis,
        .desc_diagnosis_date {
          justify-content: space-evenly;
        }

        .desc_result {
          height: 60px;
          justify-content: space-around;

          .result_choice {
            width: 112px;
            height: 48px;
            border-radius: 24px;
            text-align: center;
            line-height: 48px;

            font: {
              size: 26px;
              weight: 400;
            }

            color: #ffffff;
          }

          .result_choice {
            @include background_color("background_color1");
          }
        }

        .desc_result {
          width: 100px;
        }

        .desc_diagnosis {
          width: 152px;
        }

        .desc_diagnosis_date {
          width: 182px;
        }
      }

      .account_content.success {
        font-size: 20px;
        width: 610px;
        height: 60px;
        margin-top: 10px;
        padding-left: 30px;
        border-radius: 4px;
        color: #757575;
        background: #f5f5f5;
      }
    }

    .account_desc.success {
      height: 474px;
    }

    .btn {
      width: 690px;
      height: 84px;
      color: #ffffff;
      @include background_color("background_color1");
      box-shadow: 0px -4px 10px 0px #f1f2f3, 0px 8px 16px 0px #bce1ff;
      border-radius: 20px;
      margin: 60px auto;
      text-align: center;
      line-height: 84px;
    }

    .btn.diagnosisBtn {
      z-index: 99999;
    }
  }
}

.van-nav-bar {
  @include background_color("background_color1");

  /deep/.van-nav-bar__left {
    .van-icon {
      color: #ffffff;
      font-size: 48px;
    }
  }

  /deep/ .van-nav-bar__title {
    font: {
      size: 30px;
      weight: 500;
    }

    color: #ffffff;
  }
}

.van-hairline--bottom::after {
  border: none;
}
</style>
